import { axiosAuth, axiosPublic } from '@apiClients';

function loginWithTokenML({ email, token }) {
  return () => axiosAuth.post('/oauth/token', { email }, { headers: { 'Token-ML': token } });
}

function loginWithTokenGoogle({ email, token }) {
  return () => axiosAuth.post('/oauth/token', { email }, { headers: { 'Token-G': token } });
}

function sendMagicLink(email) {
  return () => axiosPublic.post('/users/magic-link', { email });
}

function sendCodeSMSToPhone(phone) {
  return () => axiosPublic.get(`/users/send-code/${phone}`);
}

function validateSms(phone, code) {
  return () => axiosPublic.post('/users/validate/sms', { phone, code });
}

function validateUser(token, origin) {
  return () => axiosPublic.get(`/users/validate/${token}`, { headers: { 'flow-origin': origin } });
}

function validatePhone(phone, code, tokenPhone) {
  return () => axiosPublic.post('/users/validate/phone', { phone, code, tokenPhone });
}

export default {
  loginWithTokenML,
  loginWithTokenGoogle,
  sendCodeSMSToPhone,
  sendMagicLink,
  validateUser,
  validateSms,
  validatePhone
};
